import './App.css';

import tg from './assets/TG.svg';
import twitter from './assets/Twitter.svg';
import logo from './assets/logo.png';
import man2 from './man2.png';


function App() {
  // const contract = "NutS5eEzqiZigmU2VxTm9QRiTTjPvLsWPvP7C6fuiWK";
  // const supply ="666.666.666";
  // const ticker = "$FISHLER";

  return (
    <div className="App">
      <div className='block1'>
        <img src={logo} alt=""></img>
        <div className='inside'>
          <a className='button' href="https://t.me/builduncensored" target="_blank" rel="noopener noreferrer">BUY $BUIDL</a>
          <div className="socialsBlock">
                  <a href="https://t.me/builduncensored" target="_blank" rel="noopener noreferrer"><img className="socialImg" alt="telegram" src={tg}/></a>
                  <a href="https://twitter.com/builduncensored" target="_blank" rel="noopener noreferrer"><img className="socialImg" alt="twitter" src={twitter}/></a>
                </div>
        </div>
      </div>
      <div className='block2'>
        <h1>BlackRock Uncensored International Dick Licking Tax Evasion Wire Fraud</h1>
        <p>SYMBOL: $BUIDL</p>
        <p>SUPPLY: 100</p>
        <p>BUTT PLUG: LOCK AND LOADED</p>
        <img src={man2} alt=''></img>
      </div>
    </div>
  );
}

export default App;
